import {
  getNumberOfRows,
  getVideosInRowCount,
  isGridLayout,
} from '../selectors/app-settings';
import {
  getDefaultNumberOfRows,
  getDefaultVideosInRow,
} from '@wix/wix-vod-constants/app-settings';
import { IWixStyleParams } from '@wix/native-components-infra/dist/src/types/wix-sdk';

const PAGES_TO_LOAD = 2;

export const getVideosCount = ({
  styleParams,
}: {
  styleParams: IWixStyleParams;
}): number => {
  const state = { appSettings: styleParams };
  const isGrid = isGridLayout(state);

  const numberOfRows =
    getNumberOfRows(state) || getDefaultNumberOfRows({ isGrid });
  const videosInRow =
    getVideosInRowCount(state) || getDefaultVideosInRow({ isGrid });

  return numberOfRows * videosInRow * PAGES_TO_LOAD;
};
